import React, { useState } from "react";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-primary">
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        <div className="text-white font-bold text-xl">
          Banyumas Trans
        </div>
        <div className="hidden md:flex space-x-6">
          <a href="#home" className="text-white hover:text-gray-200">Home</a>
          <a href="#features" className="text-white hover:text-gray-200">Keunggulan</a>
          <a href="#schedules" className="text-white hover:text-gray-200">Jadwal & Tarif</a>
          <a href="#testimonials" className="text-white hover:text-gray-200">Testimoni</a>
          <a href="#contact" className="text-white hover:text-gray-200">Kontak</a>
        </div>
        <button
          className="md:hidden text-white focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div className="md:hidden">
          <a href="#home" className="block px-4 py-2 text-white hover:bg-blue-700">Home</a>
          <a href="#features" className="block px-4 py-2 text-white hover:bg-blue-700">Keunggulan</a>
          <a href="#schedules" className="block px-4 py-2 text-white hover:bg-blue-700">Jadwal & Tarif</a>
          <a href="#testimonials" className="block px-4 py-2 text-white hover:bg-blue-700">Testimoni</a>
          <a href="#contact" className="block px-4 py-2 text-white hover:bg-blue-700">Kontak</a>
        </div>
      )}
    </nav>
  );
}

export default Navbar;