import React from "react";

function Footer() {
  return (
    <footer className="bg-primary text-white py-12">
      <div className="container mx-auto px-6">
        <div className="grid md:grid-cols-3 gap-8">
          {/* Informasi Kontak */}
          <div>
            <h3 className="text-xl font-bold mb-4">Hubungi Kami</h3>
            <p>Jl. Terusan Bojongsoang, Baleendah, Kec. Baleendah, Kabupaten Bandung, Jawa Barat 40375</p>
            <p>Email: banyumastravel20@gmail.com</p>
            <p>Telp: +62 851-5974-4078</p>
          </div>

          {/* Navigasi */}
          <div>
            <h3 className="text-xl font-bold mb-4">Navigasi</h3>
            <ul className="space-y-2">
              <li><a href="#home" className="hover:text-gray-200">Home</a></li>
              <li><a href="#features" className="hover:text-gray-200">Keunggulan</a></li>
              <li><a href="#schedule" className="hover:text-gray-200">Jadwal & Tarif</a></li>
              <li><a href="#testimonials" className="hover:text-gray-200">Testimoni</a></li>
              <li><a href="#contact" className="hover:text-gray-200">Kontak</a></li>
            </ul>
          </div>

          {/* Ikon Media Sosial */}
          <div>
            <h3 className="text-xl font-bold mb-4">Ikuti Kami</h3>
            <div className="flex space-x-4">
              <a href="https://www.facebook.com/profile.php?id=61566799724900&locale=id_ID" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200">
                <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                  <path d="M22 12c0-5.522-4.478-10-10-10S2 6.478 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.988h-2.54v-2.89h2.54v-2.2c0-2.508 1.492-3.89 3.777-3.89 1.094 0 2.238.196 2.238.196v2.46h-1.261c-1.243 0-1.63.772-1.63 1.562v1.872h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" />
                </svg>
              </a>
              <a href="#" rel="noopener noreferrer" className="hover:text-gray-200">
                <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 1.366.062 2.633.34 3.608 1.314.974.975 1.251 2.243 1.314 3.609.058 1.266.069 1.645.069 4.847s-.012 3.584-.069 4.85c-.062 1.366-.34 2.633-1.314 3.608-.975.974-2.243 1.251-3.609 1.314-1.266.058-1.645.069-4.847.069s-3.584-.012-4.85-.069c-1.366-.062-2.633-.34-3.608-1.314-.974-.975-1.251-2.243-1.314-3.609-.058-1.266-.069-1.645-.069-4.847s.012-3.584.069-4.85c.062-1.366.34-2.633 1.314-3.608.975-.974 2.243-1.251 3.609-1.314 1.266-.058 1.645-.069 4.847-.069zm0-2.163C8.756 0 8.325.015 7.053.072 5.737.13 4.467.384 3.444 1.408 2.42 2.433 2.166 3.702 2.108 5.018 2.052 6.289 2.037 6.719 2.037 12s.015 5.711.072 6.982c.058 1.316.313 2.585 1.337 3.609 1.024 1.025 2.293 1.279 3.609 1.337 1.271.057 1.701.072 6.982.072s5.711-.015 6.982-.072c1.316-.058 2.585-.313 3.609-1.337 1.025-1.024 1.279-2.293 1.337-3.609.057-1.271.072-1.701.072-6.982s-.015-5.711-.072-6.982c-.058-1.316-.313-2.585-1.337-3.609C19.585.384 18.316.13 17.001.072 15.73.015 15.3 0 12 0zm0 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324zm0 10.162a3.998 3.998 0 1 1 0-7.996 3.998 3.998 0 0 1 0 7.996zm6.406-11.845a1.44 1.44 0 1 0 0-2.88 1.44 1.44 0 0 0 0 2.88z" />
                </svg>
              </a>
              <a href="#" rel="noopener noreferrer" className="hover:text-gray-200">
                <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                  <path d="M23.954 4.569c-.885.392-1.83.656-2.825.775a4.93 4.93 0 0 0 2.163-2.724 9.86 9.86 0 0 1-3.127 1.184 4.914 4.914 0 0 0-8.36 4.482A13.934 13.934 0 0 1 1.671 3.149a4.822 4.822 0 0 0-.665 2.475 4.92 4.92 0 0 0 2.188 4.1 4.897 4.897 0 0 1-2.224-.616c-.053 2.28 1.581 4.415 3.946 4.89a4.935 4.935 0 0 1-2.212.084 4.927 4.927 0 0 0 4.604 3.417 9.867 9.867 0 0 1-6.102 2.105c-.395 0-.779-.023-1.162-.069a13.936 13.936 0 0 0 7.548 2.212c9.057 0 14.009-7.496 14.009-13.986 0-.209 0-.42-.016-.631A9.936 9.936 0 0 0 24 4.59z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        
        <div className="mt-8 text-center text-gray-300">
          <p>&copy; 2024 Banyumas Trans. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;