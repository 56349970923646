import React from "react";

function Features() {
  return (
    <section id="features" className="py-12 bg-gray-100">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8">Keunggulan Layanan</h2>
        <div className="grid md:grid-cols-3 gap-6">
          {/* Card 1: Door to Door Service */}
          <div className="bg-white p-6 rounded-lg shadow text-center">
            <img
              src="/car-home.png"
              alt="Door to Door Service"
              className="mx-auto mb-4 h-16 w-16"
            />
            <h3 className="text-xl font-semibold mb-2">Door to Door Service</h3>
            <p className="text-gray-700">
              Kami menjemput Anda langsung dari rumah dan mengantar sampai tujuan tanpa repot berganti kendaraan.
            </p>
          </div>

          {/* Card 2: Aman & Nyaman */}
          <div className="bg-white p-6 rounded-lg shadow text-center">
            <img
              src="/shield-seat.png"
              alt="Aman & Nyaman"
              className="mx-auto mb-4 h-16 w-16"
            />
            <h3 className="text-xl font-semibold mb-2">Aman & Nyaman</h3>
            <p className="text-gray-700">
              Armada selalu terjaga kebersihannya, dan sopir kami terlatih untuk memberikan pengalaman perjalanan yang aman.
            </p>
          </div>

          {/* Card 3: Tepat Waktu */}
          <div className="bg-white p-6 rounded-lg shadow text-center">
            <img
              src="/clock-car.png"
              alt="Tepat Waktu"
              className="mx-auto mb-4 h-16 w-16"
            />
            <h3 className="text-xl font-semibold mb-2">Tepat Waktu</h3>
            <p className="text-gray-700">
              Komitmen kami adalah tepat waktu, karena waktu Anda sangat berharga bagi kami.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;