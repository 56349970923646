import React from "react";

function Promotion() {
  return (
    <section id="promotion" className="py-12 bg-red-100">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8 text-red-700">
          Pesan Lewat Aplikasi di Play Store & Dapatkan Banyak Diskon!
        </h2>
        <div className="md:flex items-center justify-between">
          <div className="md:w-1/2">
            <h3 className="text-2xl font-semibold text-red-600 mb-4">
              Keuntungan Memesan Lewat Aplikasi
            </h3>
            <ul className="list-disc ml-5 text-gray-700 space-y-2">
              <li>Diskon hingga 20RB untuk perjalanan pertama Anda.</li>
              <li>Promo khusus setiap akhir pekan dan libur nasional.</li>
              {/* <li>Poin reward yang bisa ditukar dengan voucher perjalanan.</li> */}
              <li>Update jadwal dan pemesanan yang lebih cepat dan mudah.</li>
              <li>Dukungan 24/7 dari tim customer service kami.</li>
            </ul>
            <a
              href="https://play.google.com/store/apps/details?id=com.bmstrans"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center bg-green-500 text-white px-4 py-2 rounded-full shadow-lg hover:bg-green-600 transition duration-300 mt-6"
            >
              Download Aplikasi Sekarang
            </a>
          </div>
          <div className="md:w-1/2 mt-6 md:mt-0">
            <img
              src="/promo.jpg"
              alt="Promo Diskon"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Promotion;