import React from "react";
import Slider from "react-slick";

function Testimonials() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <section id="testimonials" className="py-12 bg-gray-100">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8">Apa Kata Pelanggan Kami</h2>
        <Slider {...settings}>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-gray-700 text-lg italic">
              "Perjalanan sangat nyaman dan sopirnya ramah. Sangat direkomendasikan!"
            </p>
            <div className="mt-4">
              <h4 className="text-xl font-semibold">Dian, Lampung</h4>
              <p className="text-gray-500">Perjalanan ke Jakarta</p>
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-gray-700 text-lg italic">
              "Saya sangat puas dengan layanan ini. Tepat waktu dan fasilitasnya sangat bagus."
            </p>
            <div className="mt-4">
              <h4 className="text-xl font-semibold">Budi, Bandung</h4>
              <p className="text-gray-500">Perjalanan ke Lampung</p>
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-gray-700 text-lg italic">
              "Layanan pelanggan yang luar biasa dan sangat membantu dalam perjalanan saya."
            </p>
            <div className="mt-4">
              <h4 className="text-xl font-semibold">Siti, Jakarta</h4>
              <p className="text-gray-500">Perjalanan ke Bandung</p>
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-gray-700 text-lg italic">
              "Harga terjangkau dengan kualitas layanan yang tidak mengecewakan."
            </p>
            <div className="mt-4">
              <h4 className="text-xl font-semibold">Agus, Jakarta</h4>
              <p className="text-gray-500">Perjalanan ke Lampung</p>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;