import React from "react";

function WhatsappButton() {
  return (
    <a
      href="https://wa.me/+6285159744078?text=Halo Banyumas trans,%20saya%20ingin%20mengetahui%20lebih%20lanjut%20tentang%20layanan%20travel%20Anda!"
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-4 right-4 bg-green-500 text-white rounded-full p-4 shadow-lg hover:bg-green-600 transition duration-300"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className="h-6 w-6"
        fill="currentColor"
      >
        <path d="M12 2c-5.514 0-10 4.486-10 10 0 1.707.441 3.42 1.279 4.899l-1.279 5.101 5.253-1.363c1.389.802 2.952 1.263 4.747 1.263 5.514 0 10-4.486 10-10s-4.486-10-10-10zm0 18c-1.541 0-3.032-.429-4.315-1.237l-.308-.186-3.112.808.845-3.366-.2-.316c-.766-1.218-1.169-2.619-1.169-4.061 0-4.411 3.589-8 8-8 4.411 0 8 3.589 8 8s-3.589 8-8 8zm4.187-5.743c-.229-.115-1.353-.67-1.562-.747-.209-.078-.361-.115-.513.115-.153.229-.591.747-.724.896-.133.153-.266.172-.495.057-.229-.115-.964-.354-1.834-1.13-.678-.605-1.136-1.354-1.27-1.583-.133-.229-.014-.354.101-.469.105-.104.229-.267.344-.4.115-.133.153-.229.229-.381.076-.153.038-.286-.019-.4-.057-.114-.513-1.235-.702-1.692-.184-.443-.372-.382-.513-.382-.133 0-.286-.019-.438-.019s-.4.057-.61.286c-.21.229-.8.781-.8 1.904s.82 2.209.936 2.362c.114.153 1.615 2.464 3.91 3.462.547.237.973.378 1.305.482.548.173 1.046.149 1.439.09.439-.065 1.353-.553 1.543-1.088.191-.535.191-.994.133-1.088-.057-.095-.21-.153-.439-.267z" />
      </svg>
    </a>
  );
}

export default WhatsappButton;