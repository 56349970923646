import React from "react";

function Facilities() {
  return (
    <section id="facilities" className="py-12 bg-gray-100">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8">Fasilitas Kami</h2>
        <div className="grid md:grid-cols-3 gap-6">
          {/* Card 1: Armada Modern */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <img
              src="/armada.jpg" // Ganti dengan path gambar Anda
              alt="Armada Modern"
              className="w-full h-48 object-cover"
            />
            <div className="p-6 text-center">
              <h3 className="text-xl font-semibold mb-4">Armada Modern</h3>
              <p className="text-gray-700">
                Kendaraan dengan fasilitas lengkap, bersih, dan nyaman untuk perjalanan jauh.
              </p>
            </div>
          </div>

          {/* Card 2: AC & WiFi Gratis */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <img
              src="/ac-wifi.jpg" // Ganti dengan path gambar Anda
              alt="AC & WiFi Gratis"
              className="w-full h-48 object-cover"
            />
            <div className="p-6 text-center">
              <h3 className="text-xl font-semibold mb-4">AC & WiFi Gratis</h3>
              <p className="text-gray-700">
                Nikmati udara sejuk dan koneksi internet tanpa batas selama perjalanan Anda.
              </p>
            </div>
          </div>

          {/* Card 3: Layanan Bagasi */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <img
              src="/hiace.jpg" // Ganti dengan path gambar Anda
              alt="Layanan Bagasi"
              className="w-full h-48 object-cover"
            />
            <div className="p-6 text-center">
              <h3 className="text-xl font-semibold mb-4">Layanan Bagasi</h3>
              <p className="text-gray-700">
                Kami menyediakan ruang bagasi yang luas dan aman untuk barang-barang Anda.
              </p>
            </div>
          </div>

          {/* Tambahkan lebih banyak fasilitas jika diperlukan */}
        </div>
      </div>
    </section>
  );
}

export default Facilities;