import React from "react";

function Gallery() {
  return (
    <section id="gallery" className="py-12">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8">Gallery Perjalanan</h2>
        <div className="grid md:grid-cols-3 gap-6">
          <div className="overflow-hidden rounded-lg shadow-lg">
            <img
              src="/gallery/1.jpeg" // Ganti dengan path gambar Anda
              alt="Perjalanan 1"
              className="w-full h-48 object-cover"
            />
          </div>
          <div className="overflow-hidden rounded-lg shadow-lg">
            <img
              src="/gallery/2.jpeg" // Ganti dengan path gambar Anda
              alt="Perjalanan 2"
              className="w-full h-48 object-cover"
            />
          </div>
          <div className="overflow-hidden rounded-lg shadow-lg">
            <img
              src="/gallery/3.jpeg" // Ganti dengan path gambar Anda
              alt="Perjalanan 3"
              className="w-full h-48 object-cover"
            />
          </div>
          {/* Tambahkan lebih banyak gambar jika diperlukan */}
        </div>
      </div>
    </section>
  );
}

export default Gallery;