import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Hero from "./components/Hero";
import Features from "./components/Features";
import Testimonials from "./components/Testimonials";
import Schedule from "./components/Schedule";
import BookingSteps from "./components/BookingSteps";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import WhatsappButton from "./components/WhatsappButton";
import BackToTopButton from "./components/BackToTopButton";
import Facilities from "./components/Facilities";
import Gallery from "./components/Gallery";
import Promotion from "./components/Promotion";
import initFacebookPixel from "./facebookPixel";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  useEffect(() => {
    initFacebookPixel();
  }, []);

  return (
    <Router>
      <div className="font-sans">
        <Navbar />
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <Hero />
                <Features />
                <Facilities />
                <Gallery />
                <Promotion />
                <Testimonials />
                <Schedule />
                <BookingSteps />
              </>
            } 
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
        <WhatsappButton />
        <BackToTopButton />
      </div>
    </Router>
  );
}

export default App;