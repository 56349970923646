import React from "react";

function BookingSteps() {
  return (
    <section id="contact" className="py-12">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8">Cara Pemesanan</h2>
        <div className="grid md:grid-cols-4 gap-6">
          <div className="text-center">
            <h3 className="text-xl font-semibold mb-2">1. Pilih Jadwal</h3>
            <p>Tentukan tanggal dan waktu keberangkatan Anda.</p>
          </div>
          <div className="text-center">
            <h3 className="text-xl font-semibold mb-2">2. Isi Data Penumpang</h3>
            <p>Lengkapi data penumpang untuk memudahkan perjalanan.</p>
          </div>
          <div className="text-center">
            <h3 className="text-xl font-semibold mb-2">3. Selesaikan Pembayaran</h3>
            <p>Gunakan metode pembayaran yang paling nyaman bagi Anda.</p>
          </div>
          <div className="text-center">
            <h3 className="text-xl font-semibold mb-2">4. Nikmati Perjalanan</h3>
            <p>Duduk dan santai, kami akan mengantar Anda dengan aman sampai tujuan.</p>
          </div>
        </div>
        {/* <div className="mt-4 text-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.bmstrans"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/playstore-logo.png"
              alt="Download on Play Store"
              className="mx-auto"
              style={{ width: "250px", height: "250px", objectFit: "contain" }}
            />
          </a>
        </div> */}
        <div className="mt-8 text-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.bmstrans"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/playstore-logo.png"
              alt="Download on Play Store"
              className="mx-auto"
              style={{ width: "250px", height: "250px", objectFit: "contain" }}
            />
          </a>
        </div>
      </div>
    </section>
  );
}

export default BookingSteps;