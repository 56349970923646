import ReactPixel from 'react-facebook-pixel';

const initFacebookPixel = () => {
  const options = {
    autoConfig: true, // Optional. Set to false if you don't want automatic configuration.
    debug: false, // Set to true to enable logging for debugging.
  };

  ReactPixel.init('537981052331036', {}, options); // Replace 'YOUR_PIXEL_ID' with the actual Pixel ID.
  ReactPixel.pageView(); // For tracking page views.
};

export default initFacebookPixel;